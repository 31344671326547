import './App.css';
import Home from '../src/views/Home/Home'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Rewards from './views/Rewards/Rewards';
import Header from './views/Header/Header';
import Footer from './views/Footer/Footer';
import Layout from './views/Layout/Layout';
import About from './views/AboutUs/About';
import Deposit from './views/Deposit/Deposit';
import Withdraw from './views/Wihdrawal/Withdraw';
import Payout from './views/Payouts/Payout';
import BeGambleAware from './views/BeGambleAware/BeGambleAware';
import Terms from './views/Terms/Terms';
import Privacy from './views/Privacy/Privacy';
import Rules from './views/Rules/Rules';
import SignIn from './views/Auth/SignIn';
import SignUp from './views/Auth/SignUp';
import PlayIn from './views/PlayIn/PlayIn';
import LiveCasino from './views/LiveCasino/LiveCasino';
import Slots from './views/Slots/Slots';
import Sports from './views/Sports/Sports';
import Casino from './views/Casino/Casino';
import Promotion from './views/promotions/Promotion';
import Disclaimer from './views/Disclaimer/Disclaimer';

function App() {
  
  return (
    <BrowserRouter >
    {/* <Header/> */}
      <Routes>
      <Route path="/" element={<Layout />}>
          <Route path='/' element={<Home />} />
          <Route path='/signIn' element={<SignIn />} />
          <Route path='/signUp' element={<SignUp />} />
          <Route path='/deposit' element={<Deposit />} />
          <Route path='/payouts' element={<Payout />} />
          <Route path='/Promotions' element={<Promotion />} />
          <Route path='/disclaimer' element={<Disclaimer />} />


          <Route path='/casino' element={<Casino />} />
          <Route path='/rewards' element={<Rewards />} />
          <Route path='/about' element={<About />} />
          <Route path='/withdraw' element={<Withdraw />} />
          <Route path='/beGambleAware' element={<BeGambleAware />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/rules' element={<Rules />} />
          <Route path='/playIn' element={<PlayIn />} />
          <Route path='/liveCasino' element={<LiveCasino />} />
          <Route path='/slots' element={<Slots />} />
          <Route path='/' element={<Sports />} />



          
         
        
        </Route>

        {/* <Route >
          <Route path='/' element={<Home />} />
          <Route path='/rewards' element={<Rewards />} />
      </Route> */}
      
      </Routes>
      {/* <Footer /> */}
    </BrowserRouter>
    
  );
}

export default App;
