/* eslint-disable import/no-anonymous-default-export */
import Axios from "axios";
import api from '../api/Home'


export default {
    TITLE_DISCLAMER : (query) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await Axios.get(
               api.TITLE_DISCLAMER(query)
            );
            resolve(response);
          } catch (err) {
            reject(err);
          }
        }); 
        // GET_CONFIRMDETIALS
      },
      SPORTS_DATA : (query) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await Axios.get(
               api.SPORTS_DATA(query)
            );
            resolve(response);
          } catch (err) {
            reject(err);
          }
        }); 
        // GET_CONFIRMDETIALS
      },
      LOCATION : () => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await Axios.get(
               api.LOCATION()
            );
            resolve(response);
          } catch (err) {
            reject(err);
          }
        }); 
        // GET_CONFIRMDETIALS
      },

      ALL_PAGE_CONTAINT : (query) => {
        return new Promise(async (resolve, reject) => {
          try {
            const response = await Axios.get(
               api.PAGES_DATA(query)
            );
            resolve(response);
          } catch (err) {
            reject(err);
          }
        }); 
        // GET_CONFIRMDETIALS
      },
     
    
      
}