import React, { useEffect, useState } from 'react'
import Services from "../../services";
import DomPurify from "dompurify";
import Footer from '../Footer/Footer';
import Lolader from '../loader/Loader';

export default function Terms() {
  const [terms,setTermsData]=useState()
  const [loading, setLoading] = useState(false);

  const [location, setLocation] = useState();
  // const purifiedString = DomPurify.sanitize(footerdata)
  const language = localStorage.getItem("i18nextLng")
  const locationdata = localStorage.getItem("Location")


  const LOCATION = async () => {
    try {
      const location = await Services.Home.LOCATION();
      
      setLocation(location.data);
      GET_terms(location.data);
    } catch (error) {
      console.error(error);
    }
  };

  const GET_terms = async (location) => {
    setLoading(true)
    try {
      const query = {
        whitelabelId: 211,
        country: location.CountryCode,
        lang :language,
        // playmode: 'sport',
        code: "terms",
      };
      
      const queryParams = new URLSearchParams(query).toString();
      
      const response = await Services.Home.ALL_PAGE_CONTAINT(queryParams);
      if(response){
      setTermsData(response?.data[0]?.Html);

    setLoading(false)

      }
    
    } catch (error) {
    setLoading(false)

      console.error("Error fetching terms:", error);
    }
  };


  const getDescription = () => {
    if (terms) {
      return {
        __html: DomPurify.sanitize(terms).replace(/\r?\n/g, ""),
      };
    }
  };

  useEffect(() => {
    LOCATION();
   //  GET_REWARDS()

 }, []);
  return (
    <div>
    <main className="content__container bg-black p-10">
        <div className="page__content">

        {loading && loading==true?(
        <div className="loader flex  justify-center content-center self-center items-center	m-20 justify-self-center	">
          <Lolader/>
          {/* Your loader component or animation */}
          {/* Loading... */}
        </div>
      ):(<div>
                {terms && <div dangerouslySetInnerHTML={getDescription()} />}


      </div>)}
       
        </div>
       
        </main>
        <Footer/>
    </div>
  )
}
