/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import Services from "../../services";
import Slider from "react-slick";
import Lolader from "../loader/Loader";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CasinoSlider from "./casinoSlider";
const language = localStorage.getItem("i18nextLng")
 

export default function Promotion() {
  const [sportsData, setSportsData] = useState();
  const [loading, setLoading] = useState(false);




  const SPORTS_DATA = async () => {
    setLoading(true)
    try {
      const query = {
        whitelabelId: 211,
        country:  localStorage.getItem("Location"),
        // country:  "ZA",
        // lang :language,
        playMode: "sport",
      };
      const queryParams = new URLSearchParams(query).toString();

      const response = await Services.Home.SPORTS_DATA(queryParams);
      if(response){
        setLoading(false)
        const slicedArray = response?.data.slice(0, 20);
      setSportsData(slicedArray);
      }

    
    } catch (error) {
      setLoading(false)

      console.log(error);
    }
  };

  useEffect(() => {
    SPORTS_DATA();
  }, []);

  const options = {
    items: 5,
    loop: true,
    speed: 250,
    nav:true,
    autoplay: false,
    dots:false,
   responsive : {
     
      0 : {
        items : 3,
          
      },
     
      480 : {
        items : 4,
         
      },
     
      768 : {
        items : 5,
        
      },
      1024 : {
        items : 8,
        
      }
  }
  
  };
  return (
    <div>

{loading && loading==true?(
        <div className="loader flex  justify-center content-center self-center items-center	m-20 justify-self-center	">
          <Lolader/>
          {/* Your loader component or animation */}
          {/* Loading... */}
        </div>
      ):(<div>
             <div className="bg-gray-900">
        <div className="mx-auto max-w-7xl p-4 xl:px-0" data-v-b75ae699>
          <div
            className="mb-4 flex justify-between items-center mx-1"
            data-v-b75ae699
          >
           
            <div className="flex items-center text-white">
              <svg
               
                viewBox="0 0 512 512"
                className="h-5 w-5 fill-current mt-2"
              >
                <path
                  className="fa-primary"
                  d="M1 278.9c-.7-7.5-1-15.2-1-22.9C0 191.5 23.8 132.6 63.1 87.6l40.1 100.1c2.4 6 2.6 12.6 .6 18.8s-6.1 11.4-11.6 14.8L1 278.9zM199 505.6C130 489.9 71.6 446.4 36.2 387.4l107.6-7.2c6.4-.4 12.8 1.4 18.1 5.2s9 9.3 10.5 15.6L199 505.6zM475.8 387.4C440.4 446.4 382 489.9 313 505.6L339.5 401c1.6-6.3 5.3-11.8 10.5-15.6s11.6-5.6 18.1-5.2l107.6 7.2zM448.9 87.6C488.2 132.6 512 191.5 512 256c0 7.7-.3 15.3-1 22.9l-91.3-57.5c-5.5-3.4-9.6-8.7-11.6-14.8s-1.8-12.8 .6-18.8L448.9 87.6zM356.6 20.5L273.7 89.6c-5 4.1-11.2 6.4-17.7 6.4s-12.7-2.3-17.7-6.4L155.4 20.5C186.3 7.3 220.3 0 256 0s69.7 7.3 100.6 20.5zM241.9 166.3c8.4-6.1 19.8-6.1 28.2 0L337 214.8c8.4 6.1 11.9 16.9 8.7 26.8l-25.5 78.6c-3.2 9.9-12.4 16.6-22.8 16.6H214.7c-10.4 0-19.6-6.7-22.8-16.6l-25.6-78.6c-3.2-9.9 .3-20.7 8.7-26.8l66.9-48.6z"
                />
                <path
                  className="fa-secondary"
                  d="M155.4 20.5C119.8 35.8 88.3 58.9 63.1 87.6l40.1 100.1c2.4 6 2.6 12.6 .6 18.8s-6.1 11.4-11.6 14.8L1 278.9c3.5 39.4 15.9 76.3 35.2 108.5l107.6-7.2c6.4-.4 12.8 1.4 18.1 5.2s9 9.3 10.5 15.6L199 505.6c18.3 4.2 37.4 6.4 57 6.4s38.7-2.2 57-6.4L339.5 401c1.6-6.3 5.3-11.8 10.5-15.6s11.6-5.6 18.1-5.2l107.6 7.2c19.3-32.2 31.7-69.1 35.2-108.5l-91.3-57.5c-5.5-3.4-9.6-8.7-11.6-14.8s-1.8-12.8 .6-18.8L448.9 87.6c-25.1-28.8-56.6-51.9-92.3-67.1L273.7 89.6c-5 4.1-11.2 6.4-17.7 6.4s-12.7-2.3-17.7-6.4L155.4 20.5zm86.5 145.7c8.4-6.1 19.8-6.1 28.2 0L337 214.8c8.4 6.1 11.9 16.9 8.7 26.8l-25.5 78.6c-3.2 9.9-12.4 16.6-22.8 16.6H214.7c-10.4 0-19.6-6.7-22.8-16.6l-25.6-78.6c-3.2-9.9 .3-20.7 8.7-26.8l66.9-48.6z"
                />
              </svg>
              <h2 className="font-bold tracking-tight my-0 mx-2.5"> Sports </h2>
              <Link
                to="https://www.betneptune.com/#m/login"
                className="text-sm font-semibold text-pink-600 hover:underline "
              >
                All Sports
              </Link>
            </div>
         
          </div>
          <section
            className="carousel"
            dir="ltr"
            aria-label="Gallery"
            tabIndex={0}
            data-v-b75ae699
          >
            <div className="carousel__viewport">
              <ol className="carousel__track">
              <OwlCarousel className="owl-theme" {...options}>
                <li
                  className="carousel__slide  carousel__slide--visible carousel__slide--active"
                  aria-hidden="false"
                  data-v-b75ae699
                >
                  <Link
                    to="https://www.betneptune.com/#m/login"
                    className="mx-1 bg-gray-800 flex-column text-white flex align-center flex-col justify-center justify-items-center items-center h-32 w-32 rounded-lg hover:bg-gray-700"
                    data-v-b75ae699
                  >
                    <svg
                     
                      viewBox="0 0 512 512"
                      className="h-10 w-10 fill-current mt-2"
                      data-v-b75ae699
                    >
                      <path
                        className="fa-primary"
                        d="M1 278.9c-.7-7.5-1-15.2-1-22.9C0 191.5 23.8 132.6 63.1 87.6l40.1 100.1c2.4 6 2.6 12.6 .6 18.8s-6.1 11.4-11.6 14.8L1 278.9zM199 505.6C130 489.9 71.6 446.4 36.2 387.4l107.6-7.2c6.4-.4 12.8 1.4 18.1 5.2s9 9.3 10.5 15.6L199 505.6zM475.8 387.4C440.4 446.4 382 489.9 313 505.6L339.5 401c1.6-6.3 5.3-11.8 10.5-15.6s11.6-5.6 18.1-5.2l107.6 7.2zM448.9 87.6C488.2 132.6 512 191.5 512 256c0 7.7-.3 15.3-1 22.9l-91.3-57.5c-5.5-3.4-9.6-8.7-11.6-14.8s-1.8-12.8 .6-18.8L448.9 87.6zM356.6 20.5L273.7 89.6c-5 4.1-11.2 6.4-17.7 6.4s-12.7-2.3-17.7-6.4L155.4 20.5C186.3 7.3 220.3 0 256 0s69.7 7.3 100.6 20.5zM241.9 166.3c8.4-6.1 19.8-6.1 28.2 0L337 214.8c8.4 6.1 11.9 16.9 8.7 26.8l-25.5 78.6c-3.2 9.9-12.4 16.6-22.8 16.6H214.7c-10.4 0-19.6-6.7-22.8-16.6l-25.6-78.6c-3.2-9.9 .3-20.7 8.7-26.8l66.9-48.6z"
                        data-v-b75ae699
                      />
                      <path
                        className="fa-secondary"
                        d="M155.4 20.5C119.8 35.8 88.3 58.9 63.1 87.6l40.1 100.1c2.4 6 2.6 12.6 .6 18.8s-6.1 11.4-11.6 14.8L1 278.9c3.5 39.4 15.9 76.3 35.2 108.5l107.6-7.2c6.4-.4 12.8 1.4 18.1 5.2s9 9.3 10.5 15.6L199 505.6c18.3 4.2 37.4 6.4 57 6.4s38.7-2.2 57-6.4L339.5 401c1.6-6.3 5.3-11.8 10.5-15.6s11.6-5.6 18.1-5.2l107.6 7.2c19.3-32.2 31.7-69.1 35.2-108.5l-91.3-57.5c-5.5-3.4-9.6-8.7-11.6-14.8s-1.8-12.8 .6-18.8L448.9 87.6c-25.1-28.8-56.6-51.9-92.3-67.1L273.7 89.6c-5 4.1-11.2 6.4-17.7 6.4s-12.7-2.3-17.7-6.4L155.4 20.5zm86.5 145.7c8.4-6.1 19.8-6.1 28.2 0L337 214.8c8.4 6.1 11.9 16.9 8.7 26.8l-25.5 78.6c-3.2 9.9-12.4 16.6-22.8 16.6H214.7c-10.4 0-19.6-6.7-22.8-16.6l-25.6-78.6c-3.2-9.9 .3-20.7 8.7-26.8l66.9-48.6z"
                        data-v-b75ae699
                      />
                    </svg>
                    <p className="text-sm text-white mt-2 mb-0" data-v-b75ae699>
                      Football
                    </p>
                  </Link>
                </li>
                <li
                  className="carousel__slide carousel__slide--visible carousel__slide--next"
                  aria-hidden="false"
                  data-v-b75ae699
                >
                  <Link
                    to="https://www.betneptune.com/#m/login"
                    className="mx-1 flex-column bg-gray-800 text-white flex align-center flex-col justify-center justify-items-center items-center h-32 w-32 rounded-lg hover:bg-gray-700"
                    data-v-b75ae699
                  >
                    <svg
                     
                      viewBox="0 0 512 512"
                      className="h-10 w-10 fill-lime-400 mt-2"
                      data-v-b75ae699
                    >
                      <path
                        className="fa-primary"
                        d="M305.2 507.3c-12.5 2.4-25.3 3.9-38.3 4.5c-3.8-10.7-6.6-21.8-8.4-33.1c-4.4-27.2-2.9-55 4.4-81.6s20.2-51.2 37.9-72.4s39.7-38.2 64.5-50.1s52-18.2 79.5-18.7c22.8-.4 45.5 3.3 67 10.9c-.5 13-2.1 25.8-4.5 38.3c-19.6-8.1-40.6-12.1-61.9-11.8c-22.2 .4-44 5.5-64 15.1s-37.7 23.3-52 40.3s-24.6 36.9-30.5 58.3s-7.1 43.8-3.5 65.7c1.9 11.9 5.2 23.5 9.8 34.6zM245.1 .2c10.7 30.5 13.7 63.4 8.2 95.6c-7.1 42.4-28.3 81.2-60 110.1s-72.4 46.4-115.2 49.6c-26.6 2-53.1-1.7-77.9-10.4c.5-13 2.1-25.8 4.5-38.3C26.9 216 51.1 220 75.3 218.2c34.5-2.5 67.2-16.6 92.8-39.9s42.6-54.5 48.4-88.7c4.8-28.7 1.4-58.2-9.7-84.9c12.5-2.4 25.3-3.9 38.3-4.5z"
                        data-v-b75ae699
                      />
                      <path
                        className="fa-secondary"
                        d="M.2 245.1C.1 248.7 0 252.3 0 256C0 397.4 114.6 512 256 512c3.7 0 7.3-.1 10.9-.2c-3.8-10.7-6.6-21.8-8.4-33.1c-4.4-27.2-2.9-55 4.4-81.6s20.2-51.2 37.9-72.4s39.7-38.2 64.5-50.1s52-18.2 79.5-18.7c22.8-.4 45.5 3.3 67 10.9c.2-3.6 .2-7.2 .2-10.9C512 114.6 397.4 0 256 0c-3.7 0-7.3 .1-10.9 .2c10.7 30.5 13.7 63.4 8.2 95.6c-7.1 42.4-28.3 81.2-60 110.1s-72.4 46.4-115.2 49.6c-26.6 2-53.1-1.7-77.9-10.4zM206.8 4.7C104.9 24.6 24.6 104.9 4.7 206.8C26.9 216 51.1 220 75.3 218.2c34.5-2.5 67.2-16.6 92.8-39.9s42.6-54.5 48.4-88.7c4.8-28.7 1.4-58.2-9.7-84.9zM445.4 293.4c-22.2 .4-44 5.5-64 15.1s-37.7 23.3-52 40.3s-24.6 36.9-30.5 58.3s-7.1 43.8-3.5 65.7c1.9 11.9 5.2 23.5 9.8 34.6c101.9-19.8 182.2-100.2 202.1-202.1c-19.6-8.1-40.6-12.1-61.9-11.8z"
                        data-v-b75ae699
                      />
                    </svg>
                    <p className="text-sm text-white mt-2 mb-0" data-v-b75ae699>
                      Tennis
                    </p>
                  </Link>
                </li>
                <li
                  className="carousel__slide carousel__slide--visible"
                  aria-hidden="false"
                  data-v-b75ae699
                >
                  <Link
                    to="https://www.betneptune.com/#m/login"
                    className="mx-1 flex-column bg-gray-800 text-white flex align-center flex-col justify-center justify-items-center items-center h-32 w-32 rounded-lg hover:bg-gray-700"
                    data-v-b75ae699
                  >
                    <svg
                     
                      viewBox="0 0 640 512"
                      className="h-10 w-10 fill-yellow-900 mt-2"
                      data-v-b75ae699
                    >
                      <path
                        className="fa-primary"
                        d="M64 464V316.9c0-108.4 68.3-205.1 170.5-241.3L404.2 15.5C425.6 7.9 448 23.8 448 46.4c0 11-5.5 21.2-14.6 27.3L400 96c48.1 0 91.2 29.8 108.1 74.9l48.6 129.5c11.8 31.4 4.1 66.8-19.6 90.5c-16 16-37.8 25.1-60.5 25.1h-3.4c-26.1 0-50.9-11.6-67.6-31.7l-32.3-38.7c-11.7 4.1-24.2 6.4-37.3 6.4l-.1 0 0 0c-6.3 0-12.5-.5-18.6-1.5c-3.6-.6-7.2-1.4-10.7-2.3l0 0c-28.9-7.8-53.1-26.8-67.8-52.2c-4.4-7.6-14.2-10.3-21.9-5.8s-10.3 14.2-5.8 21.9c24 41.5 68.3 70 119.3 71.9l47.2 70.8c4 6.1 6.2 13.2 6.2 20.4c0 20.3-16.5 36.8-36.8 36.8H112c-26.5 0-48-21.5-48-48zM392 224a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"
                        data-v-b75ae699
                      />
                      <path
                        className="fa-secondary"
                        d="M306.8 348.1c-28.9-7.8-53.1-26.8-67.8-52.2c-4.4-7.6-14.2-10.3-21.9-5.8s-10.3 14.2-5.8 21.9c24 41.5 68.3 70 119.3 71.9l-23.8-35.8z"
                        data-v-b75ae699
                      />
                    </svg>
                    <p className="text-sm text-white mt-2 mb-0" data-v-b75ae699>
                      Horse Racing
                    </p>
                  </Link>
                </li>
                <li
                  className="carousel__slide carousel__slide--visible"
                  aria-hidden="false"
                  data-v-b75ae699
                >
                  <Link
                    to="https://www.betneptune.com/#m/login"
                    className="mx-1 flex-column  bg-gray-800 text-white flex align-center flex-col justify-center justify-items-center items-center h-32 w-32 rounded-lg hover:bg-gray-700"
                    data-v-b75ae699
                  >
                    <svg
                     
                      viewBox="0 0 512 512"
                      className="h-10 w-10 fill-orange-500 mt-2"
                      data-v-b75ae699
                    >
                      <path
                        className="fa-primary"
                        d="M86.6 64l85.2 85.2C194.5 121.7 208 86.4 208 48c0-14.7-2-28.9-5.7-42.4c10.4-2.2 21-3.8 31.9-4.7C238 16 240 31.8 240 48c0 47.3-17.1 90.5-45.4 124L256 233.4 425.4 64c8 7.1 15.6 14.6 22.6 22.6L278.6 256 340 317.4c33.4-28.3 76.7-45.4 124-45.4c16.2 0 32 2 47.1 5.8c-.9 10.8-2.5 21.5-4.7 31.9C492.9 306 478.7 304 464 304c-38.4 0-73.7 13.5-101.3 36.1L448 425.4c-7.1 8-14.6 15.6-22.6 22.6l-85.2-85.2C317.5 390.3 304 425.6 304 464c0 14.7 2 28.9 5.7 42.4c-10.4 2.2-21 3.8-31.9 4.7C274 496 272 480.2 272 464c0-47.3 17.1-90.5 45.4-124L256 278.6 86.6 448c-8-7.1-15.6-14.6-22.6-22.6L233.4 256 172 194.6C138.5 222.9 95.3 240 48 240c-16.2 0-32-2-47.1-5.8c.9-10.8 2.5-21.5 4.7-31.9C19.1 206 33.3 208 48 208c38.4 0 73.7-13.5 101.3-36.1L64 86.6c7.1-8 14.6-15.6 22.6-22.6z"
                        data-v-b75ae699
                      />
                      <path
                        className="fa-secondary"
                        d="M86.6 64l85.2 85.2C194.5 121.7 208 86.4 208 48c0-14.7-2-28.9-5.7-42.4C158.6 15 119 35.5 86.6 64zM64 86.6C35.5 119 15 158.6 5.6 202.3C19.1 206 33.3 208 48 208c38.4 0 73.7-13.5 101.3-36.1L64 86.6zM256 0c-7.3 0-14.6 .3-21.8 .9C238 16 240 31.8 240 48c0 47.3-17.1 90.5-45.4 124L256 233.4 425.4 64C380.2 24.2 320.9 0 256 0zM48 240c-16.2 0-32-2-47.1-5.8C.3 241.4 0 248.7 0 256c0 64.9 24.2 124.2 64 169.4L233.4 256 172 194.6C138.5 222.9 95.3 240 48 240zm463.1 37.8c.6-7.2 .9-14.5 .9-21.8c0-64.9-24.2-124.2-64-169.4L278.6 256 340 317.4c33.4-28.3 76.7-45.4 124-45.4c16.2 0 32 2 47.1 5.8zm-4.7 31.9C492.9 306 478.7 304 464 304c-38.4 0-73.7 13.5-101.3 36.1L448 425.4c28.5-32.3 49.1-71.9 58.4-115.7zM340.1 362.7C317.5 390.3 304 425.6 304 464c0 14.7 2 28.9 5.7 42.4C353.4 497 393 476.5 425.4 448l-85.2-85.2zM317.4 340L256 278.6 86.6 448c45.1 39.8 104.4 64 169.4 64c7.3 0 14.6-.3 21.8-.9C274 496 272 480.2 272 464c0-47.3 17.1-90.5 45.4-124z"
                        data-v-b75ae699
                      />
                    </svg>
                    <p className="text-sm text-white mt-2 mb-0" data-v-b75ae699>
                      Basketball
                    </p>
                  </Link>
                </li>
                <li
                  className="carousel__slide carousel__slide--visible"
                  aria-hidden="false"
                  data-v-b75ae699
                >
                  <Link
                    to="https://www.betneptune.com/#m/login"
                    className="mx-1 flex-column bg-gray-800 text-white flex align-center flex-col justify-center justify-items-center items-center h-32 w-32 rounded-lg hover:bg-gray-700"
                    data-v-b75ae699
                  >
                    <svg
                     
                      viewBox="0 0 640 512"
                      className="h-10 w-10 fill-purple-600 mt-2"
                      data-v-b75ae699
                    >
                      <path
                        className="fa-primary"
                        d="M192 176c-13.3 0-24 10.7-24 24v32H136c-13.3 0-24 10.7-24 24s10.7 24 24 24h32v32c0 13.3 10.7 24 24 24s24-10.7 24-24V280h32c13.3 0 24-10.7 24-24s-10.7-24-24-24H216V200c0-13.3-10.7-24-24-24z"
                        data-v-b75ae699
                      />
                      <path
                        className="fa-secondary"
                        d="M192 64C86 64 0 150 0 256S86 448 192 448H448c106 0 192-86 192-192s-86-192-192-192H192zM496 168a40 40 0 1 1 0 80 40 40 0 1 1 0-80zM392 304a40 40 0 1 1 80 0 40 40 0 1 1 -80 0zM168 200c0-13.3 10.7-24 24-24s24 10.7 24 24v32h32c13.3 0 24 10.7 24 24s-10.7 24-24 24H216v32c0 13.3-10.7 24-24 24s-24-10.7-24-24V280H136c-13.3 0-24-10.7-24-24s10.7-24 24-24h32V200z"
                        data-v-b75ae699
                      />
                    </svg>
                    <p className="text-sm text-white mt-2 mb-0" data-v-b75ae699>
                      eSports
                    </p>
                  </Link>
                </li>
                <li
                  className="carousel__slide carousel__slide--visible"
                  aria-hidden="false"
                  data-v-b75ae699
                >
                  <Link
                    to="https://www.betneptune.com/#m/login"
                    className="mx-1 flex-column bg-gray-800 text-white flex align-center flex-col justify-center justify-items-center items-center h-32 w-32 rounded-lg hover:bg-gray-700"
                    data-v-b75ae699
                  >
                    <svg
                     
                      viewBox="0 0 512 512"
                      className="h-10 w-10 fill-emerald-600 mt-2"
                      data-v-b75ae699
                    >
                      <path
                        className="fa-primary"
                        d="M502.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-135 135 45.3 45.3 135-135c12.5-12.5 12.5-32.8 0-45.3zM385.9 288H240c-8.8 0-16-7.2-16-16V126.1l-32 32V272c0 26.5 21.5 48 48 48H353.9l32-32zM512 432a80 80 0 1 0 -160 0 80 80 0 1 0 160 0z"
                        data-v-b75ae699
                      />
                      <path
                        className="fa-secondary"
                        d="M192 158.1L16.1 334C6.1 344-.6 358.4 .5 374.4c.8 11.3 2.8 28.6 8.2 46.4C13.8 438.1 22.9 457.9 39 473c15 16.2 34.9 25.2 52.2 30.4c17.8 5.4 35.1 7.4 46.4 8.2c16 1.1 30.4-5.6 40.4-15.6L353.9 320H240c-26.5 0-48-21.5-48-48V158.1zM385.9 288l23-23c4.5-4.5 7-10.6 7-17s-2.5-12.5-7-17L281 103c-9.4-9.4-24.6-9.4-33.9 0l-23 23V272c0 8.8 7.2 16 16 16H385.9z"
                        data-v-b75ae699
                      />
                    </svg>
                    <p className="text-sm text-white mt-2 mb-0" data-v-b75ae699>
                      Cricket
                    </p>
                  </Link>
                </li>
                <li
                  className="carousel__slide carousel__slide--visible"
                  aria-hidden="false"
                  data-v-b75ae699
                >
                  <Link
                    className="mx-1 flex-column bg-gray-800 text-white flex align-center flex-col justify-center justify-items-center items-center h-32 w-32 rounded-lg hover:bg-gray-700"
                    data-v-b75ae699
                  >
                    <svg
                     
                      viewBox="0 0 512 512"
                      className="h-10 w-10 fill-amber-200 mt-2"
                      data-v-b75ae699
                    >
                      <path
                        className="fa-primary"
                        d="M138 28.8c20.6 18.3 38.7 39.4 53.7 62.6C95.9 136.1 30.6 220.8 7.3 316.9c5.2 21.2 13 41.5 23.1 60.3c6.7-108.7 71.9-209.9 177.1-257.9c19.5 38.6 31 81.9 32.3 127.7C162.5 294.6 110.9 368.9 90.2 451c8.9 7.5 18.2 14.5 28.1 20.8c5.6-27 14.8-53.1 27.4-77.7C232.2 454.6 338.1 468.8 433 441c15.6-14.9 29.3-31.8 40.7-50.2c-97.5 48.5-217.7 42.6-311.9-24.5c23.7-36.2 55.4-67.8 94.5-91.8c79.9 43.2 170.1 50.8 251.6 27.6c2.1-11.3 3.4-22.9 3.9-34.7c-26.1 8.7-53.4 13.8-81 15.1c9.2-105.3-31.6-204.2-103.2-272.4C307.3 4.2 286 .8 263.9 .1c90.8 60.2 145.7 167.2 134.7 282.3c-43.2-2.4-86.4-14.1-126.8-35.9c-2.5-90.9-41.1-172.7-101.9-231.7c-11 3.9-21.7 8.6-32 14z"
                        data-v-b75ae699
                      />
                      <path
                        className="fa-secondary"
                        d="M511.8 267.4c-26.1 8.7-53.4 13.8-81 15.1c9.2-105.3-31.5-204.2-103.2-272.4C434.1 41.1 512 139.5 512 256c0 3.8-.1 7.6-.2 11.4zm-3.9 34.7c-5.8 32-17.6 62-34.2 88.7c-97.5 48.5-217.7 42.6-311.9-24.5c23.7-36.2 55.4-67.7 94.5-91.8c79.9 43.2 170.1 50.8 251.6 27.6zm-236-55.5c-2.5-90.9-41.1-172.7-101.9-231.7C196.8 5.2 225.8 0 256 0c2.7 0 5.3 0 7.9 .1c90.8 60.2 145.7 167.2 134.7 282.3c-43.1-2.4-86.4-14.1-126.8-35.9zM138 28.8c20.6 18.3 38.7 39.4 53.7 62.6C95.9 136.1 30.6 220.8 7.3 316.9C2.5 297.4 0 277 0 256C0 157.2 56 71.5 138 28.8zm69.6 90.5c19.5 38.6 31 81.9 32.3 127.7C162.5 294.6 110.9 368.9 90.2 451C66 430.4 45.6 405.4 30.4 377.2c6.7-108.7 71.9-209.9 177.1-257.9zM256 512c-50.7 0-98-14.7-137.8-40.2c5.6-27 14.8-53.1 27.4-77.7C232.2 454.6 338.1 468.8 433 441c-46 44-108.3 71-177 71z"
                        data-v-b75ae699
                      />
                    </svg>
                    <p className="text-sm text-white mt-2 mb-0" data-v-b75ae699>
                      Volleyball
                    </p>
                  </Link>
                </li>
                <li
                  className="carousel__slide"
                  aria-hidden="true"
                  data-v-b75ae699
                >
                  <Link
                    to="https://www.betneptune.com/#m/login"
                    className="mx-1 flex-column  bg-gray-800 text-white flex align-center flex-col justify-center justify-items-center items-center h-32 w-32 rounded-lg hover:bg-gray-700"
                    data-v-b75ae699
                  >
                    <svg
                     
                      viewBox="0 0 512 512"
                      className="h-10 w-10 fill-lime-300 mt-2"
                      data-v-b75ae699
                    >
                      <path
                        className="fa-primary"
                        d="M416 320a96 96 0 1 0 0 192 96 96 0 1 0 0-192zM301.4 358.8c21-42 64.4-70.8 114.6-70.8c29 0 55.8 9.6 77.2 25.9c35.9-82 20.3-181.2-46.8-248.3c-87.5-87.5-229.3-87.5-316.8 0L69.5 125.7l-.6 .6L301.4 358.8zm-11.3 33.9L49.3 151.9C38.1 171.3 32 193.5 32 216.2V219c0 33.9 13.5 66.5 37.5 90.5l9.8 9.8c13.1 13.1 12.4 34.6-1.7 46.8L11 423.8C4.3 429.6 .3 438 0 446.9s3.1 17.5 9.4 23.8l32 32c6.3 6.3 14.9 9.7 23.8 9.4s17.2-4.3 23-11l57.7-66.6c12.2-14 33.7-14.8 46.8-1.7l9.8 9.8c24 24 56.6 37.5 90.5 37.5h2.7c3.1 0 6.1-.1 9.2-.3C294.2 460.9 288 439.2 288 416c0-7.9 .7-15.7 2.1-23.3z"
                        data-v-b75ae699
                      />
                      <path className="fa-secondary" d data-v-b75ae699 />
                    </svg>
                    <p className="text-sm text-white mt-2 mb-0" data-v-b75ae699>
                      Table Tennis
                    </p>
                  </Link>
                </li>
                <li
                  className="carousel__slide"
                  aria-hidden="true"
                  data-v-b75ae699
                >
                  <Link
                    to="https://www.betneptune.com/#m/login"
                    className="mx-1 flex-column bg-gray-800 text-white flex align-center flex-col justify-center justify-items-center items-center h-32 w-32 rounded-lg hover:bg-gray-700"
                    data-v-b75ae699
                  >
                    <svg
                     
                      viewBox="0 0 512 512"
                      className="h-10 w-10 fill-blue-300 mt-2"
                      data-v-b75ae699
                    >
                      <path
                        className="fa-primary"
                        d="M64.4 468.3c-7.7-6.1-14.6-13.1-20.7-20.7C17.9 395.7 73.4 280.2 176.8 176.8S395.7 17.9 447.5 43.7c7.7 6.1 14.6 13.1 20.7 20.7c25.8 51.9-29.7 167.3-133.1 270.7S116.3 494.1 64.4 468.3z"
                        data-v-b75ae699
                      />
                      <path
                        className="fa-secondary"
                        d="M368 16H336C159.3 16 16 159.3 16 336v32c0 30.1 10.4 57.7 27.7 79.6C17.9 395.7 73.4 280.2 176.8 176.8S395.7 17.9 447.5 43.7C425.7 26.4 398.1 16 368 16zM468.3 64.5c25.8 51.9-29.7 167.3-133.1 270.7S116.3 494.1 64.4 468.3C86.3 485.6 113.9 496 144 496h32c176.7 0 320-143.3 320-320V144c0-30.1-10.4-57.7-27.7-79.5z"
                        data-v-b75ae699
                      />
                    </svg>
                    <p className="text-sm text-white mt-2 mb-0" data-v-b75ae699>
                      Rugby
                    </p>
                  </Link>
                </li>
                <li
                  className="carousel__slide"
                  aria-hidden="true"
                  data-v-b75ae699
                >
                  <Link
                    to="https://www.betneptune.com/#m/login"
                    className="mx-1 flex-column bg-gray-800 text-white flex align-center flex-col justify-center justify-items-center items-center h-32 w-32 rounded-lg hover:bg-gray-700"
                    data-v-b75ae699
                  >
                    <svg
                     
                      viewBox="0 0 512 512"
                      className="h-10 w-10 fill-red-500 mt-2"
                      data-v-b75ae699
                    >
                      <path
                        className="fa-primary"
                        d="M411.4 227.6c11.3-7 26.1-3.5 33 7.8s3.5 26.1-7.8 33L319.1 341.2c.6 3.5 .9 7.1 .9 10.8c0 35.3-28.7 64-64 64s-64-28.7-64-64s28.7-64 64-64c14.1 0 27.2 4.6 37.8 12.4l117.6-72.8z"
                        data-v-b75ae699
                      />
                      <path
                        className="fa-secondary"
                        d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM288 96a32 32 0 1 1 -64 0 32 32 0 1 1 64 0zM256 416c-35.3 0-64-28.7-64-64s28.7-64 64-64c14.1 0 27.2 4.6 37.8 12.4l117.5-72.8c11.3-7 26.1-3.5 33 7.8s3.5 26.1-7.8 33L319.1 341.2c.6 3.5 .9 7.1 .9 10.8c0 35.3-28.7 64-64 64zM400 144a32 32 0 1 1 -64 0 32 32 0 1 1 64 0zM96 224a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm80-80a32 32 0 1 1 -64 0 32 32 0 1 1 64 0z"
                        data-v-b75ae699
                      />
                    </svg>
                    <p className="text-sm text-white mt-2 mb-0" data-v-b75ae699>
                      Formula 1
                    </p>
                  </Link>
                </li>
                </OwlCarousel>
              </ol>
            </div>
      
            <div
              className="carousel__liveregion carousel__sr-only"
              aria-live="polite"
              aria-atomic="true"
            >
              Item 1 of 10
            </div>
          </section>
        </div>
        <div className="mx-auto max-w-7xl p-4 xl:px-0">
          <div className="mb-4 flex justify-between items-center mx-1">
            {/*[*/}
            <div className="flex items-center text-white">
              <svg
                viewBox="0 0 16 16"
               
                className="h-5 w-5 fill-current mt-2"
              >
                <path d="m4.35514362 7.92721639c2.91007931-.66629828 4.44813965.97471551 4.3508 3.23924141-.10319138 2.4005741-1.94800517 4.3507793-4.3508 4.3507793s-4.2476-1.9502052-4.35079138-4.3507793c-.09733966-2.2645259 1.44072069-3.90553969 4.35079138-3.23924141zm3.6274931-.03738966c.7002931-.54673965 1.73978793-.75768276 3.08639998-.44887414 2.7725328-.63450172 4.237269.92980173 4.1444604 3.08638961-.0978759 2.2882311-1.8562293 4.1461937-4.1444604 4.1461937-.916142.0036007-1.80588854-.3066792-2.52110515-.8792126.48664578-.7831112.76167454-1.6791367.79817931-2.6004155.05232414-1.19472934-.30543448-2.25447245-1.00404483-2.9834759-.11162312-.11570699-.2317701-.22287617-.35942931-.32060517zm.83490862-7.85440862c.10442931-.15480172.62792931.23955.7506138.38031724.0588756.06766806.08845543.1559547.08223101.24543407-.00622442.08947938-.04774312.17282021-.11542067.2316849-.34479483.29998276-.67182931.60468793-.98477759.91256896-.00072241.00424138-.0004431.00841035-.00133103.01265173-.31438621 1.50385.62036379 2.65004483 1.52440694 3.75842586.3211948.3763426.6179392.7728841.8884086 1.18718621-.2941333-.0569208-.5921733-.09136574-.8915362-.10303621-.16120003-.21500862-.33937589-.43396207-.520969-.65664828-.79443965-.97404827-1.67392241-2.05685689-1.73426724-3.43637931-1.36350517 1.49199138-2.35765345 3.05372587-2.94072069 4.61642069-.17046724.02173276-.3431862.04895862-.51909138.08553621-.07228275-.01502241-.14352241-.02697759-.21494827-.03950517.60366552-1.75580517 1.69207758-3.50735862 3.21826034-5.16671035.15308659-.16496947.29482416-.34011798.42423966-.5242431.34505345-.49553104.69266034-.99636724 1.03490172-1.50370345zm.89024483 1.19877069c.30354653-.04891724 2.42520003.09100172 3.35097763.78911724 1.1285879.85105 1.0841086 1.17696897 1.5514758 3.41212414-1.2950707-.22524655-2.3635362-.29004828-3.7597258-1.53043448-.7561583-.67817159-1.17434952-1.65557681-1.14272763-2.6708069z" />
              </svg>
              <h2 className="font-bold tracking-tight my-0 mx-2.5"> Casino </h2>
              <Link
                to="https://www.betneptune.com/#m/login"
                className="text-sm font-semibold text-pink-600 hover:underline "
              >
                All Games
              </Link>
            </div>
            {/*]*/}
            <div className="flex space-x-2">
              {/* <div className="bg-gray-900 text-white shadow-sm ring-1 ring-inset ring-gray-800 p-2 rounded text-sm">
                Featured
              </div> */}
              {/* <button className="bg-gray-800 text-white p-2 rounded hover:bg-gray-700">
                <svg
                 
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  aria-hidden="true"
                  className="h-3 w-3 text-white"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.72 12.53a.75.75 0 010-1.06l7.5-7.5a.75.75 0 111.06 1.06L9.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5z"
                    clipRule="evenodd"
                  />
                </svg>
              </button> */}
              {/* <button className="bg-gray-800 text-white p-2 rounded hover:bg-gray-700">
                <svg
                
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  aria-hidden="true"
                  className="h-3 w-3 text-white"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
                    clipRule="evenodd"
                  />
                </svg>
              </button> */}
            </div>
          </div>
          <section
            className="carousel"
            dir="ltr"
            aria-label="Gallery"
            tabIndex={0}
          >
            <div className="carousel__viewport">
              <ol
                className="carousel__track m-0"
                style={{
                  transform: "translateX(0px)",
                  transition: "0ms",
                  margin: "",
                  width: "100%",
                }}
              >
         
     {sportsData && sportsData.length>0 ?  <CasinoSlider sportsData={sportsData}/> : ''}
                {/* </Slider> */}

              
              </ol>
            </div>
           
          </section>
        </div>
      </div>
      </div>)}
    
    </div>
  );
}