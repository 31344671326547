/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "../../App.css";
import Footer from "../Footer/Footer";
import Loader from "../loader/Loader";
import Promotion from "../promotions/Promotion";
import Containt from "../content/Containt";
import CardSlider from "../PaymentCardSlider/CardSlider";
import sportscover from "../../assets/Online Betting Site _ Sports Betting UK _ Betarno_files/betarno-sports-cover-(2).png";
import ProductInfo from "../ProductInfo/ProductInfo";
import progressplay from "../../assets/Online Betting Site _ Sports Betting UK _ Betarno_files/SportsWelcome.webp";
import Services from "../../services";
import useGetTitle from "../../hooks/useGetTitle";
import { Link } from "react-router-dom";

export default function Sports() {
  const [sportsData, setSportsData] = useState();
  const { disclamerdata, TITLE_DISCLAMER } = useGetTitle()
  const [loading, setLoading] = useState(false);

  const LOCATION = async () => {
    setLoading(true);
    try {

      await TITLE_DISCLAMER(localStorage.getItem("Location"), "sport");
      setLoading(false);

    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    LOCATION();
  }, []);

  return (
    <div>
    {loading && loading == true ? (
      <div className="loader flex  justify-center content-center self-center items-center	m-20 justify-self-center	">
        <Loader />
        {/* Your loader component or animation */}
        {/* Loading... */}
      </div>
    ) : (<div>
      <div >
        <div>
          <div className="">

            <main className="relative z-10  transition-transform duration-300 ease-in-out">
              <div className="mt-16 lg:mt-0 relative isolate overflow-hidden bg-gray-900 py-0 lg:py-16">
                {/* <div
                  className="absolute -top-0 left-0 -z-9 h-full w-full blur-3x1 opacity-0 md:opacity-50 bg-black"
                  height={800}
                /> */}
              </div>
              <div className="carousel-buner__item-bg  pl-2">
                <div className="carousel-buner__item-typography   max-w-[1240px] mx-auto mt-[65px] mb-[55px] p-5">
                      <h2 className="carousel-buner__title">
                          
                        
                        {disclamerdata?.title}
                      </h2>
                      <p className="mt-3 text-lg leading-8 text-gray-300">
                        {disclamerdata?.subTitle}
                      </p>
                      <div className="mt-5 carousel-buner__buttons">
                        <Link to="https://www.betneptune.com/?dynamic=organic&btag=organic&a=default/#m/registration">
                        <button className="btn btn-light ">Deposit Now</button>

                        </Link>
                        <Link to="https://www.betneptune.com/promotions?dynamic=organic&btag=organic&code=Welcome"
                        >
{/* Terms apply */}
                        <button className="text-sm font-medium text-slate-50 hover:text-blue-700 ">Terms apply</button>
                        </Link>
                        <p className="carousel-buner__sub-title">
                        {disclamerdata?.disclaimer}
                      </p>
                    </div>
                </div>
                <img
                      alt="Bet ₹ 1,000 & Get a ₹ 1,000 Free Bet"
                      srcSet={progressplay}
                      src={progressplay}
                      width={384}
                      height={258}
                      decoding="async"
                      data-nimg={1}
                      className="character_img__ela10 "
                      loading="lazy"
                      style={{
                        color: "transparent",
                        display: "block",
                        maxWidth: "100%",
                        objectFit: "contain",
                        objectPosition: "right center",
                      }}
                    />
              </div>
              {/* <Loader/> */}
              <CardSlider />
              <ProductInfo />
              <Promotion />
              <Containt />
              <Footer />
            </main>
          </div>
        </div>
      </div>
    </div>)}

  </div>
  )
}
