import React, { useEffect, useState } from 'react'
import "../../App.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import useGetTitle from "../../hooks/useGetTitle"
import Footer from '../Footer/Footer'
import progressplay from "../../assets/Online Betting Site _ Sports Betting UK _ Betarno_files/SportsWelcome.webp";
import reward from "../../assets/Online Betting Site _ Sports Betting UK _ Betarno_files/image1111.webp";
import services from '../../services';
import CardSlider from '../PaymentCardSlider/CardSlider';
import ProductInfo from '../ProductInfo/ProductInfo';
import Promotion from '../promotions/Promotion';
import Containt from '../content/Containt';
import { Link } from 'react-router-dom';
import Loader from '../loader/Loader';
export default function Casino() {
  const [loading, setLoading] = useState(false);

  const options = {
    items: 1,
    loop: true,
    autoplay: true,
    // Add more options as needed
  };

  const { disclamerdata, TITLE_DISCLAMER } = useGetTitle()

 

  const LOCATION = async () => {
    setLoading(true);
    try {

      await TITLE_DISCLAMER(localStorage.getItem("Location"), "casino");
      setLoading(false);

    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };



  useEffect(() => {
    // TITLE_DISCLAMER();
    LOCATION();
  }, []);
  return (
<>
       {loading && loading == true ? (
      <div className="loader flex  justify-center content-center self-center items-center	m-20 justify-self-center	">
        <Loader />
        {/* Your loader component or animation */}
        {/* Loading... */}
      </div>
    ) : (  <div>
      {/* <Slider2/> */}
      <div>

        <main className="relative z-10  transition-transform duration-300 ease-in-out">
          <div className="mt-16 lg:mt-0 relative isolate overflow-hidden bg-gray-900 py-0 lg:py-16">
            <div
              // className="absolute -top-0 left-0 -z-9 h-full w-full blur-3x1 opacity-0 md:opacity-50 bg-black"
              // height={800}
            />
            {/* <img
              src={reward}
             
              className="absolute -top-0 inset-0 -z-10 lg:h-full w-full object-center object-contain lg:object-cover"
            /> */}
            <div
              className="hidden lg:absolute lg:-top-10 lg:right-1/2 lg:-z-10 lg:mr-10 lg:block lg:transform-gpu lg:blur-3xl"
              aria-hidden="true"
            >
              <div
                className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#18a5cf] to-[#18a5cf] opacity-70"
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              />
            </div>
            <div className="carousel-buner__item-bg pl-2">
              <div className="carousel-buner__item-typography max-w-[1240px] mx-auto mt-[65px] mb-[55px]">
                <h2 className="carousel-buner__title">


                  {disclamerdata?.title}
                </h2>
                <p className="mt-3 text-lg leading-8 text-gray-300">
                  {disclamerdata?.subTitle}
                </p>
                <div className="mt-5 carousel-buner__buttons">
                <Link to="https://www.betneptune.com/?dynamic=organic&btag=organic&a=default/#m/registration">
                        <button className="btn btn-light ">Deposit Now</button>
                        </Link>
                        <Link to="https://www.betneptune.com/promotions?dynamic=organic&btag=organic&code=Welcome"
                        >

                        <button className="text-sm font-medium text-slate-50 hover:text-blue-700 ">Terms apply</button>
                        </Link>
                  <p className="carousel-buner__sub-title">
                    {disclamerdata?.disclaimer}
                  </p>
                </div>
              </div>
              <div className="carousel-buner__item-img">
                <div
                  style={{ display: "flex", width: "100%", height: "100%" }}
                >
                  <img
                    alt="Bet ₹ 1,000 & Get a ₹ 1,000 Free Bet"
                    srcSet={reward}
                    src={reward}
                    width={384}
                    height={307}
                    decoding="async"
                    data-nimg={1}
                    className="character_img__ela10"
                    loading="lazy"
                    style={{
                      color: "transparent",
                      display: "block",
                      maxWidth: "100%",
                      objectFit: "contain",
                      objectPosition: "right center",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>


        </main>

        {/* <OwlCarousel className="owl-theme" {...options}>


          <div className="item">
            <div
              className="carousel-buner__item "
              style={{
                backgroundImage:
                  'url("https://data.progressplay.net/new_site/promotion_banners/762X320/SportsWelcome.webp")',
                height: "410px",
              }}
            >
              <div className="carousel-buner__item-bg">
                <div className="carousel-buner__item-typography">
                  <h2 className="carousel-buner__title">{disclamerdata && disclamerdata?.title}</h2>
                  <p className="carousel-buner__sub-title">{disclamerdata && disclamerdata?.subTitle}</p>
                  <div className="carousel-buner__buttons">
                    <button className="btn btn-light">Deposit Now</button>
                    <p className="carousel-buner__sub-title">
                      {disclamerdata && disclamerdata?.disclaimer}
                    </p>
                  </div>
                </div>
                <div className="carousel-buner__item-img">
                  <div style={{ display: "flex", width: "100%", height: "100%" }}>
                    <img
                      alt="Bet ₹ 1,000 & Get a ₹ 1,000 Free Bet"
                      srcSet={reward}
                      src={reward}
                      width={384}
                      height={410}
                      decoding="async"
                      data-nimg={1}
                      className="character_img__ela10"
                      loading="lazy"
                      style={{
                        color: "transparent",
                        display: "block",
                        maxWidth: "100%",
                        objectFit: "contain",
                        objectPosition: "right center",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        {/* <div className="item">
          <div
            className="carousel-buner__item "
            style={{
              backgroundImage:
                'url("https://data.progressplay.net/new_site/promotion_banners/762X320/RewardsProgramme.webp")',
              height: "410px",
            }}
          >
            <div className="carousel-buner__item-bg">
              <div className="carousel-buner__item-typography">
                <h2 className="carousel-buner__title">
                 
                  {disclamerdata?.title}
                  </h2>
                <p className="carousel-buner__sub-title">
                {disclamerdata?.subTitle}
               
                </p>
                <div className="carousel-buner__buttons ">
                  <button className="btn btn-light">Deposit Now</button>
                  <p className="carousel-buner__sub-title">
                      {disclamerdata?.disclaimer}
                    </p>
                </div>
              </div>
              <div className="carousel-buner__item-img">
                <div style={{ display: "flex", width: "100%", height: "100%" }}>
                  <img
                    alt="Choose Your Rewards"
                    srcSet={progressplay}
                    src={progressplay}
                    width={384}
                    height={307}
                    decoding="async"
                    data-nimg={1}
                    className="character_img__ela10"
                    loading="lazy"
                    style={{
                      color: "transparent",
                      display: "block",
                      maxWidth: "100%",
                      objectFit: "contain",
                      objectPosition: "right center",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* </OwlCarousel> */}
        {/* 
        <div className="home-slider">
          <div className="carousel carousel-buner ">
            <div className="carousel__homepage-hero-promotions owl-carousel owl-theme ">
              <div
                className="carousel-buner__item "
                style={{
                  backgroundImage:
                    'url("https://data.progressplay.net/new_site/promotion_banners/762X320/SportsWelcome.webp")',
                }}
              >
                <div className="carousel-buner__item-bg">
                  <div className="carousel-buner__item-typography">
                    <h2 className="carousel-buner__title">
                      {disclamerdata?.title}
                    </h2>
                    <p className="carousel-buner__sub-title">
                      {disclamerdata?.subTitle}
                    </p>
                    <div className="carousel-buner__buttons">
                      <button className="btn btn-light">Deposit Now</button>
                      <p className="carousel-buner__sub-title">
                        {disclamerdata?.disclaimer}
                      </p>
                    </div>
                  </div>
                  <div className="carousel-buner__item-img">
                    <div
                      style={{ display: "flex", width: "100%", height: "100%" }}
                    >
                      <img
                        alt="Bet ₹ 1,000 & Get a ₹ 1,000 Free Bet"
                        srcSet={progressplay}
                        src={progressplay}
                        width={384}
                        height={307}
                        decoding="async"
                        data-nimg={1}
                        className="character_img__ela10"
                        loading="lazy"
                        style={{
                          color: "transparent",
                          display: "block",
                          maxWidth: "100%",
                          objectFit: "contain",
                          objectPosition: "right center",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-buner__disclaimer promotions-buner__disclaimer">
                <p>
                  <span>
                    New Players Only. Min ₹1,000 qualifying bets, stake not
                    returned. Free bet - one-time stake of ₹1,000, min odds 1.5,
                    stake not returned. 1X wager the winnings. Wager from real
                    balance first. Wager calculated on bonus bets only. Max
                    conversion: ₹20,000. Valid for 7 Days from receipt. Withdrawal
                    requests void all active/pending bonuses.
                  </span>
                  &nbsp;<a className>Full Terms apply</a>
                </p>
              </div>
              <div
                className="carousel-buner__item "
                style={{
                  backgroundImage:
                    'url("https://data.progressplay.net/new_site/promotion_banners/762X320/RewardsProgramme.webp")',
                }}
              >
                <div className="carousel-buner__item-bg">
                  <div className="carousel-buner__item-typography">
                    <h2 className="carousel-buner__title">Choose Your Rewards</h2>
                    <p className="carousel-buner__sub-title">
                      The Rewards Programme Where the More You Play, The More
                      Rewards You Get to Choose!
                    </p>
                    <div className="carousel-buner__buttons ">
                      <button className="btn btn-light">Deposit Now</button>
                    </div>
                  </div>
                  <div className="carousel-buner__item-img">
                    <div
                      style={{ display: "flex", width: "100%", height: "100%" }}
                    >
                      <img
                        alt="Choose Your Rewards"
                        srcSet={reward}
                        src={reward}
                        width={384}
                        height={307}
                        decoding="async"
                        data-nimg={1}
                        className="character_img__ela10"
                        loading="lazy"
                        style={{
                          color: "transparent",
                          display: "block",
                          maxWidth: "100%",
                          objectFit: "contain",
                          objectPosition: "right center",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-promo-nav-container" />
        </div> */}
      </div>

      {/* <CardSlider /> */}
                <ProductInfo />
                <Promotion />
                <Containt />
      <Footer />
    </div>)}</>

  
  )
}
