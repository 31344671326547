import React from "react";
import "../../App.css";
import progressplay from "../../assets/Online Betting Site _ Sports Betting UK _ Betarno_files/SportsWelcome.webp";
import reward from "../../assets/Online Betting Site _ Sports Betting UK _ Betarno_files/RewardsProgramme.webp";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export default function Slider() {
  const options = {
    items: 1,
    loop: true,
    autoplay: true,
    // Add more options as needed
  };
  return (
    <div>
      
      <OwlCarousel className="owl-theme" {...options}>
        <div className="item">
          <div
            className="carousel-buner__item "
            style={{
              backgroundImage:
                'url("https://data.progressplay.net/new_site/promotion_banners/762X320/SportsWelcome.webp")',
            }}
          >
            <div className="carousel-buner__item-bg">
              <div className="carousel-buner__item-typography">
                <h2 className="carousel-buner__title">
                  Bet ₹ 1,000 &amp; Get a ₹ 1,000 Free Bet
                </h2>
                <p className="carousel-buner__sub-title">
                  Make your first deposit today
                </p>
                <div className="carousel-buner__buttons">
                  <button className="btn btn-light">Deposit Now</button>
                </div>
              </div>
              <div className="carousel-buner__item-img">
                <div style={{ display: "flex", width: "100%", height: "100%" }}>
                  <img
                    alt="Bet ₹ 1,000 & Get a ₹ 1,000 Free Bet"
                    srcSet={progressplay}
                    src={progressplay}
                    width={384}
                    height={307}
                    decoding="async"
                    data-nimg={1}
                    className="character_img__ela10"
                    loading="lazy"
                    style={{
                      color: "transparent",
                      display: "block",
                      maxWidth: "100%",
                      objectFit: "contain",
                      objectPosition: "right center",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item">
          <div
            className="carousel-buner__item "
            style={{
              backgroundImage:
                'url("https://data.progressplay.net/new_site/promotion_banners/762X320/RewardsProgramme.webp")',
              height: "410px",
            }}
          >
            <div className="carousel-buner__item-bg">
              <div className="carousel-buner__item-typography">
                <h2 className="carousel-buner__title">Choose Your Rewards</h2>
                <p className="carousel-buner__sub-title">
                  The Rewards Programme Where the More You Play, The More
                  Rewards You Get to Choose!
                </p>
                <div className="carousel-buner__buttons ">
                  <button className="btn btn-light">Deposit Now</button>
                </div>
              </div>
              <div className="carousel-buner__item-img">
                <div style={{ display: "flex", width: "100%", height: "100%" }}>
                  <img
                    alt="Choose Your Rewards"
                    srcSet={reward}
                    src={reward}
                    width={384}
                    height={307}
                    decoding="async"
                    data-nimg={1}
                    className="character_img__ela10"
                    loading="lazy"
                    style={{
                      color: "transparent",
                      display: "block",
                      maxWidth: "100%",
                      objectFit: "contain",
                      objectPosition: "right center",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>

      <div className="home-slider">
        <div className="carousel carousel-buner ">
          <div className="carousel__homepage-hero-promotions owl-carousel owl-theme ">
            <div
              className="carousel-buner__item "
              style={{
                backgroundImage:
                  'url("https://data.progressplay.net/new_site/promotion_banners/762X320/SportsWelcome.webp")',
              }}
            >
              <div className="carousel-buner__item-bg">
                <div className="carousel-buner__item-typography ">
                  <h2 className="carousel-buner__title">
                    Bet ₹ 1,000 &amp; Get a ₹ 1,000 Free Bet
                    
                  </h2>
                  <p className="carousel-buner__sub-title">
                    Make your first deposit today
                  </p>
                  <div className="carousel-buner__buttons">
                    <button className="btn btn-light">Deposit Now</button>
                  </div>
                </div>
                <div className="carousel-buner__item-img">
                  <div
                    style={{ display: "flex", width: "100%", height: "100%" }}
                  >
                    <img
                      alt="Bet ₹ 1,000 & Get a ₹ 1,000 Free Bet"
                      srcSet={progressplay}
                      src={progressplay}
                      width={384}
                      height={307}
                      decoding="async"
                      data-nimg={1}
                      className="character_img__ela10"
                      loading="lazy"
                      style={{
                        color: "transparent",
                        display: "block",
                        maxWidth: "100%",
                        objectFit: "contain",
                        objectPosition: "right center",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-buner__disclaimer promotions-buner__disclaimer">
              <p>
                <span>
                  New Players Only. Min ₹1,000 qualifying bets, stake not
                  returned. Free bet - one-time stake of ₹1,000, min odds 1.5,
                  stake not returned. 1X wager the winnings. Wager from real
                  balance first. Wager calculated on bonus bets only. Max
                  conversion: ₹20,000. Valid for 7 Days from receipt. Withdrawal
                  requests void all active/pending bonuses.
                </span>
                &nbsp;<a className>Full Terms apply</a>
              </p>
            </div>
            <div
              className="carousel-buner__item "
              style={{
                backgroundImage:
                  'url("https://data.progressplay.net/new_site/promotion_banners/762X320/RewardsProgramme.webp")',
              }}
            >
              <div className="carousel-buner__item-bg">
                <div className="carousel-buner__item-typography">
                  <h2 className="carousel-buner__title">Choose Your Rewards</h2>
                  <p className="carousel-buner__sub-title">
                    The Rewards Programme Where the More You Play, The More
                    Rewards You Get to Choose!
                  </p>
                  <div className="carousel-buner__buttons ">
                    <button className="btn btn-light ">Deposit Now</button>
                  </div>
                </div>
                <div className="carousel-buner__item-img">
                  <div
                    style={{ display: "flex", width: "100%", height: "100%" }}
                  >
                    <img
                      alt="Choose Your Rewards"
                      srcSet={reward}
                      src={reward}
                      width={384}
                      height={307}
                      decoding="async"
                      data-nimg={1}
                      className="character_img__ela10"
                      loading="lazy"
                      style={{
                        color: "transparent",
                        display: "block",
                        maxWidth: "100%",
                        objectFit: "contain",
                        objectPosition: "right center",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-promo-nav-container" />
      </div>
    </div>
  );
}
