import React, { useEffect, useState } from 'react'
import Services from "../../services";
import DomPurify from "dompurify";
import Footer from '../Footer/Footer';
import Loader from "../loader/Loader";
import {  useSelector } from 'react-redux';
import './about.css'
import '../../App.css'


export default function About() {

  const [about,setAboutData]=useState()
  const counter = useSelector(state => state.counter.value);//
  const [location, setLocation] = useState();
  // const purifiedString = DomPurify.sanitize(footerdata)
  const language = localStorage.getItem("i18nextLng")
  const locationdata = localStorage.getItem("Location")
  const [loading, setLoading] = useState(false);



  const LOCATION = async () => {
    try {
      const location = await Services.Home.LOCATION();
      
      setLocation(location.data);
      GET_ABOUT(location.data);
    } catch (error) {
      console.error(error);
    }
  };

  const GET_ABOUT = async (location) => {
    setLoading(true);
    try {
      const query = {
        whitelabelId: 211,
        country: location.CountryCode,
        lang :language,
        // playmode: 'sport',
        code: "aboutus",
      };
      
      const queryParams = new URLSearchParams(query).toString();
      
      const response = await Services.Home.ALL_PAGE_CONTAINT(queryParams);
      if(response){
        setAboutData(response?.data[0]?.Html);
    setLoading(false);


      }
    
    } catch (error) {
    setLoading(false);

      console.error("Error fetching REWARDS:", error);
    }
  };


  const getDescription = () => {
    if (about) {
      return {
        __html: DomPurify.sanitize(about).replace(/\r?\n/g, ""),
      };
    }
  };
  useEffect(() => {
     LOCATION();
 
  }, []);


  useEffect(()=>{
    LOCATION()
  },[counter])
  return (
<div>
<main className="content__container bg-black p-10">
    <div className="page__content">
    {loading && loading==true?(
        <div className="loader flex  justify-center content-center self-center items-center	m-20 justify-self-center	">
          <Loader/>
          {/* Your loader component or animation */}
          {/* Loading... */}
        </div>
      ):(<div>
     {about && <div dangerouslySetInnerHTML={getDescription()} />}
      </div>)}
      
   
    </div>
   
    </main>
    <Footer/>
</div>
 
  )
}
