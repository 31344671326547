import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const CasinoSlider = (props) =>{
    const [sportsData, setSportsData] = useState([])
    useEffect(()=>{
        if(props.sportsData.length > 0){
            setSportsData(props.sportsData)
        }
    },[props])
    const options = {
        items: 5,
        loop: true,
        speed: 250,
        nav:true,
        autoplay: false,
        dots:false,
        margin:20,
       responsive : {
         
          0 : {
            items : 2,
              
          },
         
          480 : {
            items : 3,
             
          },
         
          768 : {
            items : 4,
            
          },
          1024 : {
            items : 5,
            
          },
          1200 : {
            items : 6,
            
          }
      }
      
      };
    return (<>
        {sportsData && sportsData.length ? <OwlCarousel className="owl-theme" {...options}>
        {sportsData?.map((item, key) => {
          return(
            <li
            key={key}
           
            className="carousel__slide carousel__slide--visible carousel__slide--active"
            aria-hidden="false"
          >
             <Link
                    to="https://www.betneptune.com/#m/login"
                    className="relative mx-1 rounded-lg overflow-hidden transform transition-transform duration-300 h-44 md:h-full hover:scale-105 z-30"
                  >
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-blue-600" />
            {item?.imageUrl &&  <img
                src={item?.imageUrl}
                // style={{minHeight:'250px'}}
                alt="Starburst Slot"
                title="Starburst"
                // width={170}
                className="rounded-lg h-full "
              />}
              <div className="absolute bottom-0 left-0 w-full text-center z-20 mb-2 px-2">
                <h2 className="font-bold tracking-tight text-sm text-white mb-1">
                  {/* Starburst */}
                  {item?.gameName ? item?.gameName : '-'}
                </h2>
                {/* <img
                  src={item?.image}
                  className="mx-auto brightness-200"
                  // style={{ maxHeight: 30 }}
                /> */}
              </div>
            </Link>
          </li>
          )
     
     
          })}
        </OwlCarousel> : null}
        </>
    )
}
export default CasinoSlider