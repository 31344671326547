/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import '../../App.css'

import watercolor from '../../assets/Online Betting Site _ Sports Betting UK _ Betarno_files/950x350-sky-blue-solid-color-background.jpg'
import stadiumCover from '../../assets/Online Betting Site _ Sports Betting UK _ Betarno_files/betarno-stadium-cover-(2).png'

export default function ProductInfo() {
  return (
    <div>
       <div className="py-10 md:py-11 relative">
                <div className="absolute -top-0 left-0 -z-9 h-full w-full blur-3x1 opacity-25 bg-black" />
                <img src={stadiumCover}  className="absolute inset-0 -z-10 h-full w-full object-cover object-center md:object-center" />
                <div className="mx-auto max-w-7xl relative ">
                  <div className=' sm:flex '>

                  <div className=' grid grid-row-2 text-center gap-y-5 p-4 '>
                    <dd className='text-[45px]'>55,000+</dd>
                    <dt className='text-[15px] font-light'>Over 55,000 pre-match sports betting events each month</dt> 
                  </div>
                  <div className=' grid grid-row-2 text-center gap-y-5 p-4 '>
                    <dd className='text-[45px]'>30,000+</dd>
                    <dt className='text-[15px] font-light'>Bet on sports live with over 30,000 in-play betting events
                        each month</dt> 
                  </div>
                  <div className=' grid grid-row-2 text-center gap-y-5  p-4 '>
                    <dd className='text-[45px]'> 140+</dd>
                    <dt className='text-[15px] font-light'>Bet on variety with over 140 sports available from around
                        the world</dt> 
                  </div>
                  <div className=' grid grid-row-2 text-center gap-y-5 p-4 '>
                    <dd className='text-[45px]'> UK &amp; MGA</dd>
                    <dt className='text-[15px] font-light'>Licenced and regulated by the UKGC and the Malta Gaming
                        Authority</dt> 
                  </div>

                </div>
                  {/* <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center  lg:grid-cols-4"> */}
                
                  {/*    <div className="mx-auto md:grid grid-cols-2 max-w-xs  border gap-y-4">
                      <h3 className="text-base leading-7 text-white">
                        Over 55,000 pre-match sports betting events each month
                      </h3>
                      <h3 className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                        55,000+
                      </h3>
                    </div>
                    <div className="mx-auto flex max-w-xs flex-col gap-y-4">
                      <dt className="text-base leading-7 text-white">
                        Bet on sports live with over 30,000 in-play betting events
                        each month
                      </dt>
                      <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                        30,000+
                      </dd>
                    </div>
                    <div className="mx-auto flex max-w-xs flex-col gap-y-4">
                      <dt className="text-base leading-7 text-white">
                        Bet on variety with over 140 sports available from around
                        the world
                      </dt>
                      <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                        140+
                      </dd>
                    </div>
                    <div className="mx-auto flex max-w-xs flex-col gap-y-4">
                      <dt className="text-base leading-7 text-white">
                        Licenced and regulated by the UKGC and the Malta Gaming
                        Authority
                      </dt>
                      <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                        UK &amp; MGA
                      </dd>
                    </div>
                    {/*]
                  </dl> */}
                </div>
              </div>
    </div>
  )
}

{/* <div>
<div className="py-16 md:py-32 relative">
         <div className="absolute -top-0 left-0 -z-9 h-full w-full blur-3x1 opacity-25 bg-black" />
         <img src={stadiumCover} alt className="absolute inset-0 -z-10 h-full w-full object-cover object-center md:object-center" />
         <div className="mx-auto max-w-7xl relative">
           <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-4">
         
             <div className="mx-auto flex max-w-xs flex-col gap-y-4">
               <dt className="text-base leading-7 text-white">
               {t("overPreMatchSportsBettingEventsEachMonth")}
               </dt>
               <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
               {t("55,000+")}
                 
                 
               </dd>
             </div>
             <div className="mx-auto flex max-w-xs flex-col gap-y-4">
               <dt className="text-base leading-7 text-white">
               {t("55,000+")}
               {t("Betonsportslivewithover30000inplaybettingeventseachmonth")}

               </dt>
               <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                 30,000+
               </dd>
             </div>
             <div className="mx-auto flex max-w-xs flex-col gap-y-4">
               <dt className="text-base leading-7 text-white">
               {t("betonvarietywithover140sportsavailablefromaroundtheworld")}
                 
               </dt>
               <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                 140+
               </dd>
             </div>
             <div className="mx-auto flex max-w-xs flex-col gap-y-4">
               <dt className="text-base leading-7 text-white">
               {t("LicencedandregulatedbytheUKGCandtheMaltaGamingAuthority")}
               </dt>
               <dd className="order-first text-3xl font-semibold tracking-tight text-white sm:text-5xl">
                 UK &amp; MGA
               </dd>
             </div>
           </dl>
         </div>
       </div>
</div> */}