import React from 'react'

export default function SignIn() {
  return (
    <div>
        <body className="bg-gray-100 h-screen flex items-center justify-center">

       <div className="bg-white p-8 rounded shadow-md w-96">
        <h2 className="text-2xl font-semibold mb-4">Login</h2>

       
        <form>
        
            <div className="mb-4">
                <label for="email" className="block text-gray-600 text-sm font-medium">Email</label>
                <input type="email" id="email" name="email" className="w-full mt-1 p-2 border rounded-md"/>
            </div>

           
            <div className="mb-6">
                <label for="password" className="block text-gray-600 text-sm font-medium">Password</label>
                <input type="password" id="password" name="password" className="w-full mt-1 p-2 border rounded-md"/>
            </div>

          
            <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded-md  hover:bg-blue-600">Login</button>
        </form>
    </div>
    </body>
    </div>
  )
}
