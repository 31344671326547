import { configureStore } from "@reduxjs/toolkit";
import { increment } from "../features/counterSlice";
import { Locations } from "../features/counterSlice";
const store = configureStore({
  reducer: {
    counter: increment,
    Locations: Locations,
  },
});

export default store;




