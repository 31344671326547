import React, { useState } from "react";
import Footer from "../Footer/Footer";
import { useTranslation } from "react-i18next";

export default function Disclaimer() {
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  return (
    <div className="relative isolate overflow-hidden bg-gray-900">

      
      <div className="html-content mx-auto max-w-7xl p-4 xl:px-0">
        <h1>
        {t("Disclaimer")}</h1>
        <hr />

        <p>
        {t("BetNeptuneCasinooperatesunderstrictregulatoryguidelinesandadherestoalllegalrequirementsofitsjurisdictionItistheresponsibilityofeachplayertoensurethattheyareactingwithinthelegalframeworkoftheirrespectivecountryorregionwhenaccessingourcasinoplatform")}
         
        </p>

        <p>
        {t("BetNeptuneCasinodoesnotacceptplayersfromcountriesorregionswhereonlinegamblingisprohibitedorregulatedbylawsthatconflictwithourtermsofservicePlayersareadvisedtoverifythelegalstatusofonlinegamblingintheirjurisdictionbeforeparticipatinginanycasinoactivities")}
        </p>
        {/* <hr /> */}
        <p>
        {t("BetNeptuneCasinoshallnotbeheldliableforanylegalviolationsorconsequencesfacedbyplayerswhoaccessourservicesfromjurisdictionswheresuchactivitiesarenotpermittedItisthesoleresponsibilityoftheplayertounderstandandcomplywithanylawsorregulationstowhichtheyaresubjectregardingonlinegambling")}
         
        </p>
        <p>
        {t("ThiswebsiteanditscontentareprovidedonanasisbasiscompletenessoftheinformationcontainedonthiswebsiteThecasinoshallnotbeliableforanydamagesorlossesarisingfromtheuseorinabilitytousethissiteoritscontents")}

          
        </p>
        <p>
        {t("PlayersshouldbeawarethatgamblinginvolvesriskandshouldengageinitresponsiblyandwithintheirfinancialmeansForfurtherinformationorassistanceregardingresponsiblegamblingpleaserefertoourResponsibleGamingsectionorcontactourcustomersupportteam")}
         
        </p>
        <p>
        {t("Disclaimer")}
          BetNeptune Casino reserves the right to modify these terms and
          conditions at any time without prior notice. It is the responsibility
          of the player to regularly review these terms and conditions for any
          changes.
        </p>
        <p>
        {t("Disclaimer")}
          By accessing and using the BetNeptune Casino website, players
          acknowledge and agree to abide by these terms and conditions
        </p>
        <p>Play responsibly and enjoy your time at BetNeptune Casino.</p>
      </div>


      <div
              className="modal fade"
              id="exampleModal"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Modal title
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">...</div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button type="button" className="btn btn-primary">
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
      <Footer/>
    </div>
  );
}
