import React from "react";
import { useTranslation } from "react-i18next";

export default function Containt() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="relative isolate overflow-hidden bg-gray-900">
        <div className="html-content mx-auto max-w-7xl p-4 xl:px-0">
          <h1>
            {t("ExploringtheWorldofOnlineGamingAComprehensiveGuidetoBetNeptuneCasino")} </h1>
          <h2 className="font-bold mt-4">
            {t("IntroductiontoBetNeptuneCasinoAPremierOnlineGamingDestination")} </h2>
          <p>
            {t("BetNeptuneCasinohasemergedasastellaronlinegamingplatformofferingadiverserangeofcasinogamestosatisfyeverytypeofplayerEstablishedwiththeaimofprovidingauniqueandthrillinggamingexperienceBetNeptunecombinesclassiccasinoelementswithmoderntechnologicaladvancements")} </p>
          <h2 className="font-bold mt-4">
            {t("AnArrayofExceptionalCasinoGames")} </h2>
          <p className="font-bold mt-4">
            {t("BetNeptuneboastsanextensivecollectionofcasinogamesincludingSlotMachinesADiverseRangeofThemesandStyles")}
          </p>
          <ul>
            <li>
              {t("ClassicSlotsTimelessgamesfortraditionalists")}   </li>
            <li>
              {t("VideoSlotsCuttingedgegraphicsandimmersivestorylines")} </li>
            <li>
              {t("ProgressiveJackpotsOpportunitiesforlifechangingwins")} </li>
          </ul>

          <p className="font-bold mt-4">
            {t("TableGamesTheEpitomeofCasinoElegance")} </p>
          <ul>
            <li>
              {t("RouletteVariantsAmericanEuropeanandFrenchstyles")} </li>
            <li>
              {t("BlackjackAvarietyofrulesetsandstakes")} </li>
            <li>
              {t("PokerGamesIncludingTexasHoldemandCaribbeanStud")}  </li>
          </ul>

          <p className="font-bold mt-4">
            {t("LiveCasinoRealTimeThrills")}  </p>
          <ul>
            <li>
              {t("LiveDealersBringingthecasinofloortoyourscreen")} </li>
            <li>
              {t("InteractiveFeaturesChatwithdealersandplayers")}  </li>
            <li>
              {t("AuthenticAtmosphereRealcasinoexperiencefromthecomfortofhome")} </li>
          </ul>

          <h2 className="font-bold mt-4">
            {t("UserExperienceandInterface")}
          </h2>
          <p>
            {t("BetNeptuneplacesahighpriorityonuserexperienceThewebsitedesignisintuitivemakingnavigationseamlessfornewandexperiencedplayersalikeThecasinoiscompatiblewithvariousdevicesensuringasmoothgamingexperienceondesktopstabletsandsmartphones")}
          </p>

          <h2 className="font-bold mt-4">
            {t("SecurityandFairPlay")} </h2>
          <p>
            {t("SecurityisparamountatBetNeptuneCasinoTheyemployadvancedencryptiontechnologiestosafeguardplayerdataandtransactionsFurthermorehecasinoiscommittedtofairplaywithgamesregularlyauditedbyindependentbodiestoensurefairnessandrandomness")}
          </p>

          <h2 className="font-bold mt-4">
            {t("PromotionsandBonuses")} </h2>
          <p>
            {t("BetNeptuneoffersanarrayofpromotionsandbonuses")}  </p>
          <ul>
            <li>
              {t("WelcomeBonusesAttractiveoffersfornewplayers")} </li>
            <li>
              {t("RegularPromotionsWeeklyandmonthlyincentives")} </li>
            <li>
              {t("VIPProgrammeRewardsforloyalplayers")}  </li>
          </ul>

          <h2 className="font-bold mt-4">
            {t("CustomerSupportAlwaysThereforYou")}  </h2>
          <p>
            {t("Thecasinoprovidescomprehensivecustomersupport")} </p>
          <ul>
            <li>
              {t("LiveChatImmediateassistance")} </li>
            <li>
              {t("EmailSupportDetailedqueriesandconcerns")}  </li>
            <li>
              {t("ComprehensiveFAQSectionAnswerstocommonquestions")} </li>
          </ul>

          <h2 className="font-bold mt-4">
            {t("ResponsibleGamingACommitmenttoPlayerWellbeing")}</h2>
          <p className="font-bold">
            {t("BetNeptuneisdedicatedtoresponsiblegamingTheyoffervarioustoolsandresourcestohelpplayersmanagetheirgaminghabitsincludingdepositlimitsselfexclusionoptionsandlinkstoprofessionalsupportorganisations")} </p>
          <h2 className="font-bold mt-4">
            {t("ConclusionAPremierDestinationforOnlineGamingEnthusiasts")}
          </h2>
          <p>
            {t("BetNeptuneCasinostandsoutasatoptieronlinegamingplatformWithitsextensivegamelibraryuserfriendlyinterfacerobustsecuritymeasuresenticingpromotionsandcommitmenttoresponsiblegamingBetNeptuneoffersanunparalleledonlinecasinoexperienceEmbarkonathrillingjourneyatBetNeptuneCasinowheregamingexcellencemeetsunparalleledserviceandsecurity")}

          </p>
        </div>
        <div
          className="absolute top-0 -z-5 -translate-x-10 blur-3xl xl:top-20"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#18a5cf] to-[#18a5cf] opacity-25"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
