
import Auth from "./auth";
import Footer from "./Footer";
import deposits from "./Deposits";
import Home from "./Home";
import rewards from "./Rewards";


export default {
  Auth,
  Footer,
  deposits,
  Home,
  rewards,
 
};