import React from 'react'
import Loader from "react-js-loader";
import { InfinitySpin } from 'react-loader-spinner';

export default function Lolader() {
  return (
  <InfinitySpin
  visible={true}
  width="200"
  color="#05ddfa"
  ariaLabel="infinity-spin-loading"
  />
  )
}
