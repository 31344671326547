// import { createSlice } from '@reduxjs/toolkit';

// const counterSlice = createSlice({
//   name: 'counter',
//   initialState: {
//     value: 0
//   },
//   reducers: {
//     increment: state => {
//       state.value += 1;
//     },
//     decrement: state => {
//       state.value -= 1;
//     }
//   }
// });

// export const { increment, decrement } = counterSlice.actions;
// export default counterSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const counterSlice = createSlice({
  name: "counter",
  initialState: {
    value: 0,
  },
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
  },
});

const locationSlice = createSlice({
  name: "LocationSlice",
  initialState: {
    value: "",
  },
  reducers: {
    Locations: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { increment } = counterSlice.actions;
export const { Locations } = locationSlice.actions;

export default {
  increment: counterSlice.reducer,
  Locations: locationSlice.reducer,
};
