import React, { useState } from 'react'
import Header from '../Header/Header'
import { Outlet } from 'react-router-dom'

export default function Layout() {
  const changeData = (data) => {
    console.log("dataChange", data)

  }
  return (
    <div>
      <Header data={changeData} />

      <Outlet />
    </div>
  )
}
