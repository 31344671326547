import React, { useEffect, useState } from 'react'
import "../../App.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import useGetTitle from "../../hooks/useGetTitle"
import Footer from '../Footer/Footer'
import CardSlider from '../PaymentCardSlider/CardSlider';
import ProductInfo from '../ProductInfo/ProductInfo';
import Promotion from '../promotions/Promotion';
import Containt from '../content/Containt';
import slots from "../../assets/Online Betting Site _ Sports Betting UK _ Betarno_files/playinlivecompo (2).png";
import { Link } from 'react-router-dom';
import Lolader from '../loader/Loader';

export default function Slots() {
  const [loading, setLoading] = useState(false);



  const { disclamerdata, TITLE_DISCLAMER } = useGetTitle()

 

  const LOCATION = async () => {
    setLoading(true);
    try {

      await TITLE_DISCLAMER(localStorage.getItem("Location"), "casino");
      setLoading(false);

    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };



  useEffect(() => {
    // TITLE_DISCLAMER();
    LOCATION();
  }, []);
  return (
    <>
    
    {loading && loading == true ? (
      <div className="loader flex  justify-center content-center self-center items-center	m-20 justify-self-center	">
        <Lolader />
        {/* Your loader component or animation */}
        {/* Loading... */}
      </div>
    ) : ( <div>


      <div>
  
      <main className="relative z-10  transition-transform duration-300 ease-in-out">
          <div className="mt-16 lg:mt-0 relative isolate overflow-hidden bg-gray-900 py-0 lg:py-16">
            <div
              // className="absolute -top-0 left-0 -z-9 h-full w-full blur-3x1 opacity-0 md:opacity-50 bg-black"
              // height={800}
            />
            <img
              src={slots}
              alt
              className="absolute -top-0 inset-0 -z-10 lg:h-full w-full object-center object-contain lg:object-cover"
            />
            <div
              className="hidden lg:absolute lg:-top-10 lg:right-1/2 lg:-z-10 lg:mr-10 lg:block lg:transform-gpu lg:blur-3xl"
              aria-hidden="true"
            >
              <div
                className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#18a5cf] to-[#18a5cf] opacity-70"
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              />
            </div>
            <div className="carousel-buner__item-bg pl-2">
              <div className="carousel-buner__item-typography max-w-[1240px] mx-auto mt-[65px] mb-[55px]">
                <h2 className="carousel-buner__title">
  
  
                  {disclamerdata?.title}
                </h2>
                <p className="mt-3 text-lg leading-8 text-gray-300">
                  {disclamerdata?.subTitle}
                </p>
                <div className="mt-5 carousel-buner__buttons">
                <Link to="https://www.betneptune.com/?dynamic=organic&btag=organic&a=default/#m/registration">
                          <button className="btn btn-light ">Deposit Now</button>
                          </Link>

                          <Link to="https://www.betneptune.com/promotions?dynamic=organic&btag=organic&code=Welcome"
                        >
{/* Terms apply */}
                        <button className="text-sm font-medium text-slate-50 hover:text-blue-700 ">Terms apply</button>
                        </Link>
                  <p className="carousel-buner__sub-title">
                    {disclamerdata?.disclaimer}
                  </p>
                </div>
              </div>
              <div className="carousel-buner__item-img">
                <div
                  style={{ display: "flex", width: "100%", height: "60%" }}
                >
                  <img
                    alt="Bet ₹ 1,000 & Get a ₹ 1,000 Free Bet"
                    srcSet={slots}
                    src={slots}
                    width={384}
                    height={307}
                    decoding="async"
                    data-nimg={1}
                    className="character_img__ela10"
                    loading="lazy"
                    style={{
                      color: "transparent",
                      display: "block",
                      maxWidth: "100%",
                      objectFit: "contain",
                      objectPosition: "right center",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
  
  
        </main>
  
      </div>
  
      {/* <CardSlider /> */}
                <ProductInfo />
                <Promotion />
                <Containt />
      <Footer />
    </div>)}</>
   
  )
}
