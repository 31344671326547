import React, { useEffect, useState } from 'react'
import Services from "../../services";
import DomPurify from "dompurify";
import Footer from '../Footer/Footer';

export default function Withdraw() {
  const [withdraw,setWithdrawData]=useState()

  const [location, setLocation] = useState();
  // const purifiedString = DomPurify.sanitize(footerdata)
  const language = localStorage.getItem("i18nextLng")
  const locationdata = localStorage.getItem("Location")


  const LOCATION = async () => {
    try {
      const location = await Services.Home.LOCATION();
      
      setLocation(location.data);
      GET_Withdraw(location.data);
    } catch (error) {
      console.error(error);
    }
  };

  const GET_Withdraw = async (location) => {
    try {
      const query = {
        whitelabelId: 211,
        country: location.CountryCode,
        lang :language,
        // playmode: 'sport',
        code: "withdrawals",
      };
      
      const queryParams = new URLSearchParams(query).toString();
      
      const response = await Services.Home.ALL_PAGE_CONTAINT(queryParams);
    
      setWithdrawData(response?.data[0]?.Html);
    } catch (error) {
      console.error("Error fetching REWARDS:", error);
    }
  };


  const getDescription = () => {
    if (withdraw) {
      return {
        __html: DomPurify.sanitize(withdraw).replace(/\r?\n/g, ""),
      };
    }
  };
  useEffect(() => {
    LOCATION();
    //  GET_REWARDS()
 
  }, []);
  return (
    <div>
    <main className="content__container bg-black p-10">
        <div className="page__content">
          {withdraw && <div dangerouslySetInnerHTML={getDescription()} />}
       
        </div>
       
        </main>
        <Footer/>
    </div>
  )
}
