import React, { useEffect, useState } from 'react'
import Slider from '../slider/Slider'
import './Rewards.css'
import Services from "../../services";
import DomPurify from "dompurify";
import Footer from '../Footer/Footer';
import Lolader from '../loader/Loader';



export default function Rewards() {
  const [rewards,setRewardsData]=useState()
  const [loading, setLoading] = useState(false);


  const [location, setLocation] = useState();
  const language = localStorage.getItem("i18nextLng")

  // const purifiedString = DomPurify.sanitize(footerdata)

  const LOCATION = async () => {
    try {
      const location = await Services.Home.LOCATION();
      
      setLocation(location.data);
      GET_REWARDS(location.data);
    } catch (error) {
      console.error(error);
    }
  };

  const GET_REWARDS = async (location) => {
    setLoading(true)
    try {
      const query = {
        whitelabelId: 211,
        country: location.CountryCode,
        // playmode: 'sport',
        lang :language,
        code: "rewards",
      };
      
      const queryParams = new URLSearchParams(query).toString();
      
      const response = await Services.rewards.GET_REWARDS(queryParams);
      if(response)
    {
      setRewardsData(response?.data[0]?.Html);

    setLoading(false)

    }
    } catch (error) {
    setLoading(false)

      console.error("Error fetching REWARDS:", error);
    }
  };


  const getDescription = () => {
    if (rewards) {
      return {
        __html: DomPurify.sanitize(rewards).replace(/\r?\n/g, ""),
      };
    }
  };
  useEffect(() => {
     LOCATION();
    //  GET_REWARDS()
 
  }, []);
  return (
    <div>
        {/* <h1>heloooo</h1> */}
     

     <main className="content__container bg-black p-10"><div className="page__content">

     {loading && loading==true?(
        <div className="loader flex  justify-center content-center self-center items-center	m-20 justify-self-center	">
          <Lolader/>
          {/* Your loader component or animation */}
          {/* Loading... */}
        </div>
      ):(<div>
         <Slider/>
             {rewards && <div dangerouslySetInnerHTML={getDescription()} />}

      </div>)}
   
      {/* <div className="content-area ">
      <img src="https://data.progressplay.net/content/general/rewardsEN.jpg" width="100%" />

      <h1>Rewards Programme</h1>
      <h3>The More You Play, The More Rewards You Get!</h3>
      <p>Do you love to be spoilt with prizes and rewards? </p>
      <p>Then, you will love our Rewards Programme!</p>
      <br />
      <h4>How to join?</h4>
      <p>You’re already a member. Simply start playing or take on your first mission. </p>
      <p>To track your progress, select the trophy icon when logged in on your desktop computer or from the menu on your mobile device. 
      </p>
      <br />
      <h4>How to earn points and climb the ranks?</h4>
      <ul>
        <li className='text-white'><b>Missions</b> - Complete specific tasks, such as trying out a new game, claiming a bonus, or triggering a specific feature on a slot. With new missions regularly, you’ll be earning points in no time at all.</li>
        <li className='text-white'><b>Levels &amp; Badges</b> –The more you play, the higher you will rank, and the more achievement badges you will receive that unlock exclusive offers and advantages. 
        </li>
      </ul>
      <br />
      <h4>What can you do with your points?</h4>
      <ul>
        <li className='text-white'><b>Rewards Store</b> – Redeem your points in the Rewards Store for fantastic offers including Free Spins, Deposit Bonuses, Cashback deals and extras.</li>
        <li className='text-white'><b>Win Prizes</b> – Your progress and position on the leaderboards will earn you prizes, exclusive deals, first access to new games, tournaments and more.</li>
      </ul>
      <br />
      <p><b>Start Reeling in The Rewards Today!</b></p>
    </div> */}
    </div>
    
    
    
    </main>
    <Footer/>

    </div>
  )
}
