/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import logo from "../../assets/Online Betting Site _ Sports Betting UK _ Betarno_files/BetNeptune-Logo copy.png";

import { Link } from "react-router-dom";
import Services from "../../services";
import DomPurify from "dompurify";
import "./Footer.css";

export default function Footer() {
  const [footerdata, setFooterData] = useState();
  
  const language = localStorage.getItem("i18nextLng");
  const purifiedString = DomPurify.sanitize(footerdata)


  const GET_FOOTER = async () => {
    try {
      const query = {
        whitelabelId: 211,
        country: localStorage.getItem("Location"),
        lang: language,
        code: "footerclient",
      };
      const queryParams = new URLSearchParams(query).toString();
      const response = await Services.Footer.GET_FOOTER(queryParams);
      setFooterData(response?.data[0]?.Html);
    } catch (error) {
      console.error("Error fetching footer:", error);
    }
  };

  const getDescription = () => {
    if (footerdata) {
      // Use DOMParser to parse the HTML content
      const parser = new DOMParser();
      const doc = parser.parseFromString(footerdata, 'text/html');

      // Selectively render only the desired parts
      const withoutDisclaimer = Array.from(doc.body.childNodes)
        .filter(node => !node.classList || !node.classList.contains('disclaimer'))
        .map(node => new XMLSerializer().serializeToString(node))
        .join('');

      return { __html: withoutDisclaimer };
    }
  };

  useEffect(() => {
    GET_FOOTER();
  }, []);
  return (
    <div>
      <div className="relative isolate overflow-hidden bg-gray-900 p-4 sm:py-16 border-t border-gray-600">
        <div className="mx-auto max-w-7xl pb-6 lg:pb-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-6">
            <div className="max-w-xl lg:max-w-lg">
              <img className="h-8 w-auto" src={logo} />
            </div>
            <div className="grid grid-cols-2 colspan-5 gap-x-2 gap-y-2 font-semibold leading-7 text-white text-sm sm:grid-cols-2 md:flex lg:gap-x-10">
              <Link to="/" target="_self" rel="noopener noreferrer">
                Home
              </Link>
              <Link
                to="https://www.betneptune.com/about-us-info"
                target="_self"
                rel="noopener noreferrer"
              >
                About
              </Link>
              <Link
                to="https://www.betneptune.com/deposits-info"
                target="_self"
                rel="noopener noreferrer"
              >
                Deposits
              </Link>
              <Link
                to="https://www.betneptune.com/withdrawals-info"
                target="_self"
                rel="noopener noreferrer"
              >
                Withdrawals
              </Link>
              <Link
                to="https://www.betneptune.com/rewards-programme-info"
                target="_self"
                rel="noopener noreferrer"
              >
                Rewards
              </Link>
              <Link
                to="https://www.betneptune.com/game-payouts-info"
                target="_self"
                rel="noopener noreferrer"
              >
                Payouts
              </Link>
              {/* <Link
                to="/beGambleAware"
                target="_self"
                rel="noopener noreferrer"
              >
                BeGambleAware
              </Link> */}

              <Link to="/disclaimer" target="_blank" rel="noopener noreferrer">
                Disclaimer
              </Link>
              <Link
                to="https://www.betneptune.com/terms-conditions-info"
                target="_self"
                rel="noopener noreferrer"
              >
                Terms
              </Link>
              <Link
                to="https://www.betneptune.com/privacy-policy-info"
                target="_self"
                rel="noopener noreferrer"
              >
                Privacy
              </Link>
              <Link
                to="https://www.betneptune.com/sport-rules"
                target="_self"
                rel="noopener noreferrer"
              >
                Rules
              </Link>
              <Link
                to="https://rubyaffiliates.com/"
                target="_self"
                rel="noopener noreferrer"
              >
                Affiliates
              </Link>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-10 mt-16 lg:mt-16 ">
            <div className="colspan-2">
              <p class="text-white font-semibold">BetNeptune © 2024</p>
              <p class="mt-4 leading-8 text-gray-300 text-sm">
                BetNeptune is operated by ProgressPlay Limited of Soho Office,
                3A, Punchbowl Centre, Elia Zammit Street, St. Julians, STJ3154,
                Malta. ProgressPlay is a limited liability company registered in
                Malta (C58305), that is licensed and regulated by Malta Gaming
                Authority and operates under a License Number of
                MGA/B2C/231/2012 issued on 16th April, 2013; ProgressPlay
                Limited is licensed and regulated in Great Britain by the
                Gambling Commission under account number 39335. Gambling can be
                addictive. Play responsibly.
              </p>
            </div>
            <dl className="grid grid-cols-1 gap-x-8 gap-y-10  lg:pt-2">
              {/* <div class="flex flex-col flex-column items-start">
                <div class="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                  <svg
                   
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="h-6 w-6 text-white"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                    ></path>
                  </svg>
                </div>
                <dt class="mt-4 font-semibold text-white">Weekly Promotions</dt>
                <dd class="mt-2 leading-7 text-gray-400">
                  Sign up today and get access to
                  <span class="text-gray-300">
                    weekly online casino bonuses
                  </span>{" "}
                  and
                  <span class="text-gray-300">boosted bets</span> on the biggest
                  sporting events of the year.
                </dd>
              </div> */}
              <div class="flex flex-col flex-column items-start">
                <div class="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                  <svg
                   
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                    class="h-6 w-6 text-white"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10.05 4.575a1.575 1.575 0 10-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 013.15 0v1.5m-3.15 0l.075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 013.15 0V15M6.9 7.575a1.575 1.575 0 10-3.15 0v8.175a6.75 6.75 0 006.75 6.75h2.018a5.25 5.25 0 003.712-1.538l1.732-1.732a5.25 5.25 0 001.538-3.712l.003-2.024a.668.668 0 01.198-.471 1.575 1.575 0 10-2.228-2.228 3.818 3.818 0 00-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0116.35 15m.002 0h-.002"
                    ></path>
                  </svg>
                </div>
                <dt class="mt-4 font-semibold text-white">
                  Responsible Gambling
                </dt>
                <dd class="mt-2 leading-7 text-gray-400">
                  At BetNeptune we are committed to responsible gambling, that's
                  why we offer a variety of tools to help you manage your
                  gaming.
                </dd>
              </div>
            </dl>
          </div>

        
          {footerdata && <div dangerouslySetInnerHTML={getDescription()} />}
        </div>
        <p className="grid grid-cols-1 gap-1 place-content-center place-items-center ">
            © 2024 BetNeptune Casino. All Rights Reserved.
          </p>
        <div
          className="absolute left-1/2 top-0 -z-10 -translate-x-10 blur-3xl xl:top-20"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#18a5cf] to-[#18a5cf] opacity-25"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
